import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/OLPG_9Vgp6A">
    <SEO title="Encounters with Jesus - Reset" />
  </Layout>
)

export default SermonPost
